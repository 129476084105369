import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SideNavService} from '../shared/services/side-nav.service';
import {MatDrawer} from '@angular/material';
import {MediaMatcher} from '@angular/cdk/layout';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', {static: true}) public sidenav: MatDrawer;

  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  constructor(
    private sideNavService: SideNavService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 992px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit() {
    this.sidenavToggle();
    this.onRouteChange();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  sidenavToggle() {
    this.sideNavService.setSidenav(this.sidenav);
  }

  onRouteChange(): void {
    this.router.events.subscribe(() => {
      if (this.mobileQuery.matches) {
        this.sideNavService.close();
      }
    });
  }
}
